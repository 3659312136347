import React from "react";

import { useCustomFetch, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";

import { IUseAnalysis } from "./types";
import { ISolicitationData } from "../types";
import {
  IContractData,
  IContractPremiations,
  SolicitationStatus,
} from "../../../../types";
import { formatCNPJ, formatDate } from "../../../../../../helpers/format";
import { useNavigate } from "react-router-dom";
import { IUseSolicitationList } from "../../hooks/types";

interface Props {
  contractData: IContractData;
  contractPremiations: IContractPremiations;
  solicitationList: IUseSolicitationList;
}

export function useAnalysis({
  contractData,
  contractPremiations,
  solicitationList,
}: Props): IUseAnalysis {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const {
    solicitationDate,
    description,
    customer,
    value,
    averagePrice,
    interestPerMonth,
    deliveryDoc,
    deliveredDoc,
    chargeShipping,
    shippingValue,
    dueDate,
    totalQuantity,
    productGroup,
    payPremiation,
  } = contractData;

  const { premiations } = contractPremiations;

  const {
    searchTotalSolicitationRecordsAndSolicitations,
    filter: solicitationListFilters,
  } = solicitationList;

  const [solicitationData, setSolicitationData] =
    React.useState<ISolicitationData | null>(null);

  const gettingSolicitationData = useLoading();
  const approvingSolicitation = useLoading();
  const disapprovingSolicitation = useLoading();

  const getDataPromise = React.useCallback(
    (solicitationId: number) => {
      return new Promise<DefaultFetchResponse<ISolicitationData>>(
        async (resolve, reject) => {
          try {
            const json = (await customFetch(
              `/contracts/financialGetSolicitationHeader/${solicitationId}`,
              {
                method: "GET",
              }
            )) as DefaultFetchResponse<ISolicitationData>;
            if (json.status === 200) {
              const solicitation = json.object;

              setSolicitationData(solicitation);

              solicitationDate.setValue(
                formatDate(solicitation.dataSolicitacao, "yyyy-MM-dd")
              );
              description.setValue(solicitation.descricaoContrato);
              customer.setValue({
                label: `${solicitation.cliente.razaoSocial} (${
                  solicitation.cliente.nomeFantasia
                }) | ${
                  formatCNPJ(solicitation.cliente.documento) || "SEM CNPJ"
                }`,
                value: {
                  ...solicitation.cliente,
                  id: solicitation.cliente.idCliente,
                  cnpj: solicitation.cliente.documento,
                },
              });
              value.setValue(String(solicitation.valorContrato));
              averagePrice.setValue(String(solicitation.precoMedio));
              interestPerMonth.setValue(String(solicitation.valorJuros));
              deliveryDoc.setValue(
                formatDate(solicitation.dataEntrega, "yyyy-MM-dd")
              );
              deliveredDoc.setValue(() => {
                if (solicitation.status === SolicitationStatus.Revisado) {
                  return {
                    value: true,
                    label: "Sim",
                  };
                } else {
                  return {
                    value: false,
                    label: "Não",
                  };
                }
              });
              chargeShipping.setValue(() => {
                if (solicitation.cobrarFrete === "S") {
                  return {
                    value: true,
                    label: "Sim",
                  };
                } else {
                  return {
                    value: false,
                    label: "Não",
                  };
                }
              });
              shippingValue.setValue(String(solicitation.valorFrete));
              for (let i = 0; i < 12; i++) {
                const date = new Date(solicitation[`vencimento${i + 1}`]);
                if (date.getFullYear() !== 1) {
                  dueDate[i].setValue(() => {
                    return formatDate(date, "yyyy-MM-dd");
                  });
                }
              }
              totalQuantity.setValue(String(solicitation.quantidadeTotal));
              productGroup.setValue({
                value: solicitation.grupoForm,
                label: `${solicitation.grupoForm.descricaoGrupo}`,
              });

              payPremiation.setValue(() => {
                if (solicitation.pagarPremiacao === "S") {
                  return {
                    value: true,
                    label: "Sim",
                  };
                } else {
                  return {
                    value: false,
                    label: "Não",
                  };
                }
              });

              premiations.insertPremiations(
                solicitation.premiacao.map((premiation) => ({
                  premiationType: premiation.tipoPremiacao,
                  calcType: premiation.tipoCalculo,
                  value: String(premiation.valorPremiacao),
                }))
              );
            }
            resolve(json);
          } catch (error: any) {
            reject(error.message);
          }
        }
      );
    },
    [
      solicitationDate,
      averagePrice,
      interestPerMonth,
      chargeShipping,
      shippingValue,
      customFetch,
      deliveredDoc,
      deliveryDoc,
      description,
      customer,
      dueDate,
      productGroup,
      totalQuantity,
      value,
      payPremiation,
      premiations,
    ]
  );

  const getSolicitationData = React.useCallback(
    async (solicitationId: number) => {
      try {
        gettingSolicitationData.setLoading(true);
        const data = await getDataPromise(solicitationId);
        if (data.status === 500) {
          Modal.error(data.message, data.object);
        } else if (data.status === 404) {
          Modal.alert("Nenhum dado encontrado!");
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        gettingSolicitationData.setLoading(false);
      }
    },
    [Modal, getDataPromise, gettingSolicitationData]
  );

  const handleApproveSolicitation = async () => {
    let body = {
      idSolicitacao: solicitationData?.idSolicitacao,
    };
    try {
      approvingSolicitation.setLoading(true);
      customFetch("/contracts/financialApproveSolicitation", {
        body,
      });
      searchTotalSolicitationRecordsAndSolicitations(
        solicitationListFilters.description.value
      );
      await Modal.success(
        <>
          A solicitação está em processo de conversão para contrato.
          <br />
          Enviaremos um e-mail assim que o processo for concluído! &#128521;
        </>
      );
      navigate("/contratos/financeiro/solicitacoes");
    } catch (error) {
      Modal.error(error);
    } finally {
      approvingSolicitation.setLoading(false);
    }
  };

  const handleDisapproveSolicitation = async () => {
    let body = {
      idSolicitacao: solicitationData?.idSolicitacao,
    };
    try {
      disapprovingSolicitation.setLoading(true);
      const json = await customFetch(
        "/contracts/financialDisapproveSolicitation",
        {
          method: "DELETE",
          body,
        }
      );
      if (json.status === 200) {
        searchTotalSolicitationRecordsAndSolicitations(
          solicitationListFilters.description.value
        );
        await Modal.success(json.message);
        navigate("/contratos/financeiro/solicitacoes");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      disapprovingSolicitation.setLoading(false);
    }
  };

  return {
    solicitationData,
    gettingSolicitationData: gettingSolicitationData.isLoading,
    approvingSolicitation: approvingSolicitation.isLoading,
    disapprovingSolicitation: disapprovingSolicitation.isLoading,
    getSolicitationData,
    handleSubmit: {
      approve: handleApproveSolicitation,
      disapprove: handleDisapproveSolicitation,
    },
  };
}
