import React from "react";

import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";

import { IUseAnalysis } from "./types";
import { ISolicitationData } from "../types";
import {
  IContractData,
  IContractPremiations,
  IContractSellers,
  IContractSupervisors,
  IContractTableAnalysis,
  SolicitationStatus,
} from "../../../types";
import { formatCNPJ, formatDate } from "../../../../../helpers/format";
import { useNavigate } from "react-router-dom";
import { IUseChanges } from "../../../../../hooks/contexts/useChanges";
import { ISolicitation } from "../../types";
import { IUseSolicitationList } from "../../hooks/types";

import styles from "../../../components/ContractTable/ContractTable.module.css";

interface Props {
  contractData: IContractData;
  contractPremiations: IContractPremiations;
  contractSellers: IContractSellers;
  contractSupervisors: IContractSupervisors;
  contractTable: IContractTableAnalysis;
  solicitationList: IUseSolicitationList;
}

export function useAnalysis({
  contractData,
  contractPremiations,
  contractSellers,
  contractSupervisors,
  contractTable,
  solicitationList,
}: Props): IUseAnalysis {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const {
    interestCalculated,
    solicitationDate,
    table,
    description,
    customer,
    value,
    averagePrice,
    interestPerMonth,
    deliveryDoc,
    deliveredDoc,
    chargeShipping,
    shippingValue,
    embedShipping,
    dueDate,
    totalQuantity,
    productGroup,
    payPremiation,
  } = contractData;

  const { premiations } = contractPremiations;

  const { sellers } = contractSellers;

  const { supervisors } = contractSupervisors;

  const { items, defaultColor, defaultLetter } = contractTable;

  const {
    searchTotalSolicitationRecordsAndSolicitations,
    filter: solicitationListFilters,
  } = solicitationList;

  const [solicitationData, setSolicitationData] =
    React.useState<ISolicitationData | null>(null);

  const [permissions, setPermissions] = React.useState<string[]>([]);

  const gettingSolicitationData = useLoading();
  const savingReview = useLoading();
  const approvingSolicitation = useLoading();
  const disapprovingSolicitation = useLoading();

  const handleChangeInterestRelatedPromise = React.useCallback<
    (values: {
      interestPerMonth: string;
      solicitationDate: string;
      dueDates: string[];
    }) => Promise<void>
  >(
    (values: {
      interestPerMonth: string;
      solicitationDate: string;
      dueDates: string[];
    }) => {
      return new Promise(async (resolve, reject) => {
        const { interestPerMonth, solicitationDate, dueDates } = values;

        const body = {
          dataSolicitacao: solicitationDate,
          valorJurosAM: interestPerMonth,
          vencimentos: dueDates,
        };

        try {
          const json = (await customFetch("/contracts/calcInterest", {
            body,
          })) as DefaultFetchResponse<number>;
          if (json.status === 200) {
            interestCalculated[1](json.object);
            resolve();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
            interestCalculated[1](1);
            reject(json.message);
          }
        } catch (error: any) {
          Modal.error(error.message);
          reject(error.message);
        }
      });
    },
    [Modal, customFetch, interestCalculated]
  );

  const getDataPromise = React.useCallback(
    (solicitationId: number) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = (await customFetch(
            `/contracts/getSolicitationData/${solicitationId}`,
            {
              method: "GET",
            }
          )) as DefaultFetchResponse<ISolicitationData[]>;
          if (json.status === 200) {
            const solicitation = json.object[0];

            setSolicitationData(solicitation);

            solicitationDate.setValue(
              formatDate(solicitation.dataSolicitacao, "yyyy-MM-dd")
            );
            description.setValue(solicitation.descricaoContrato);
            customer.setValue({
              label: `${solicitation.cliente.razaoSocial} (${
                solicitation.cliente.nomeFantasia
              }) | ${formatCNPJ(solicitation.cliente.documento) || "SEM CNPJ"}`,
              value: {
                ...solicitation.cliente,
                id: solicitation.cliente.idCliente,
                cnpj: solicitation.cliente.documento,
              },
            });
            value.setValue(String(solicitation.valorContrato));
            averagePrice.setValue(String(solicitation.precoMedio));
            interestPerMonth.setValue(String(solicitation.valorJuros));
            deliveryDoc.setValue(
              formatDate(solicitation.dataEntrega, "yyyy-MM-dd")
            );
            deliveredDoc.setValue(() => {
              if (solicitation.status === SolicitationStatus.Revisado) {
                return {
                  value: true,
                  label: "Sim",
                };
              } else {
                return {
                  value: false,
                  label: "Não",
                };
              }
            });
            chargeShipping.setValue(() => {
              if (solicitation.cobrarFrete === "S") {
                return {
                  value: true,
                  label: "Sim",
                };
              } else {
                return {
                  value: false,
                  label: "Não",
                };
              }
            });
            shippingValue.setValue(String(solicitation.valorFrete || 0));
            embedShipping.setValue(() => {
              if (solicitation.embutirFrete === "S") {
                return {
                  value: true,
                  label: "Sim",
                };
              } else {
                return {
                  value: false,
                  label: "Não",
                };
              }
            });
            const dueDatesForInterestRelated: string[] = [];
            for (let i = 0; i < 12; i++) {
              const date = new Date(solicitation[`vencimento${i + 1}`]);
              if (date.getFullYear() !== 1) {
                dueDatesForInterestRelated[i] = formatDate(date, "yyyy-MM-dd");
                dueDate[i].setValue(() => {
                  return formatDate(date, "yyyy-MM-dd");
                });
              }
            }
            totalQuantity.setValue(String(solicitation.quantidadeTotal));
            productGroup.setValue({
              value: solicitation.grupoForm,
              label: `${solicitation.grupoForm.descricaoGrupo}`,
            });
            payPremiation.setValue(() => {
              if (solicitation.pagarPremiacao === "S") {
                return {
                  value: true,
                  label: "Sim",
                };
              } else {
                return {
                  value: false,
                  label: "Não",
                };
              }
            });

            premiations.insertPremiations(
              solicitation.premiacao.map((premiation) => ({
                premiationType: premiation.tipoPremiacao,
                calcType: premiation.tipoCalculo,
                value: String(premiation.valorPremiacao),
              }))
            );

            sellers.setValue(
              solicitation.solicitacaoVendedores.map((seller) => {
                const { idVendedor, ...rest } = seller;
                return {
                  label: `${seller.idVendedor} | ${seller.vendedor.nomeVendedor}`,
                  value: {
                    ...rest,
                    id: idVendedor,
                  },
                };
              })
            );

            supervisors.setValue(
              solicitation.solicitacaoSupervisores.map((supervisor) => {
                const { idSupervisor, ...rest } = supervisor;
                return {
                  label: `${supervisor.idSupervisor} | ${supervisor.supervisor.nomeSupervisor}`,
                  value: {
                    ...rest,
                    id: idSupervisor,
                  },
                };
              })
            );

            table.setValue({
              value: solicitation.tabelaVigencia,
              label: `${solicitation.tabelaVigencia.idTabelaVigencia} | ${solicitation.tabelaVigencia.nomeTabelaVigencia}`,
            });

            if (solicitation.corPadraoFaixa) {
              defaultColor.setValue({
                value: solicitation.corPadraoFaixa,
                label: (
                  <div className={styles.selectColorLabel}>
                    <span
                      className={styles.selectOptionBall}
                      style={{ backgroundColor: solicitation.corPadraoFaixa }}
                    />
                    <p>{solicitation.corPadraoFaixa}</p>
                  </div>
                ),
              });
            }
            if (solicitation.letraPadraoFaixa) {
              defaultLetter.setValue(solicitation.letraPadraoFaixa);
            }

            const selectedItems = solicitation.solicitacaoItens.map((item) => ({
              idItemVigencia: item.idItemVigencia,
              idItemSolicitacao: item.idItemSolicitacao,
            }));
            const selectedRanges = solicitation.solicitacaoItens.map(
              (item) => item.idFaixa
            );
            const formatedItems = JSON.parse(
              JSON.stringify(solicitation.tabelaVigencia.itensTabelaVigencia)
            ) as ISolicitationData["tabelaVigencia"]["itensTabelaVigencia"];
            formatedItems.forEach((item) => {
              if (selectedItems.some((i) => i.idItemVigencia === item.id)) {
                item.idItemSolicitacao = selectedItems.find(
                  (i) => i.idItemVigencia === item.id
                )!.idItemSolicitacao;
                item.usarFormula = true;
              } else {
                item.idItemSolicitacao = 0;
                item.usarFormula = false;
              }
              item.faixasVigencia.forEach((range) => {
                if (selectedRanges.includes(range.idFaixaComissao)) {
                  range.faixasPrecoProdutoAdubo.escolhida = true;
                }
              });
            });

            items.setValue(formatedItems);

            await handleChangeInterestRelatedPromise({
              solicitationDate: solicitation.dataSolicitacao,
              interestPerMonth: String(solicitation.valorJuros),
              dueDates: dueDatesForInterestRelated,
            });
          }
          resolve(json);
        } catch (error: any) {
          reject(error.message);
        }
      });
    },
    [
      solicitationDate,
      averagePrice,
      chargeShipping,
      shippingValue,
      embedShipping,
      customFetch,
      deliveredDoc,
      deliveryDoc,
      description,
      customer,
      dueDate,
      interestPerMonth,
      items,
      productGroup,
      sellers,
      supervisors,
      table,
      totalQuantity,
      value,
      defaultColor,
      defaultLetter,
      payPremiation,
      premiations,
      handleChangeInterestRelatedPromise,
    ]
  );

  const getPermissionsPromise = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = (await customFetch(
          "/contracts/commercialSearchUserPermissions",
          {
            method: "GET",
          }
        )) as DefaultFetchResponse<
          { idPermissao: number; nomePermissao: string }[]
        >;
        if (json.status === 200) {
          setPermissions(
            json.object.map((permission) => permission.nomePermissao)
          );
        }
        resolve(json);
      } catch (error: any) {
        reject(error.message);
      }
    });
  }, [customFetch]);

  const getSolicitationData = React.useCallback(
    async (solicitationId: number) => {
      try {
        gettingSolicitationData.setLoading(true);
        const [data, permissions] = (await Promise.all([
          getDataPromise(solicitationId),
          getPermissionsPromise(),
        ])) as [any, any];
        if (data.status === 500) {
          Modal.error(data.message, data.object);
        } else if (permissions.status === 500) {
          Modal.error(permissions.message, permissions.object);
        } else if (data.status === 404 && permissions.status === 404) {
          Modal.alert("Nenhum dado encontrado!");
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        gettingSolicitationData.setLoading(false);
      }
    },
    [gettingSolicitationData, getDataPromise, getPermissionsPromise, Modal]
  );

  const handleSaveReview = React.useCallback(
    async (
      changesDetected: IUseChanges,
      selectedSolicitation: ISolicitation
    ) => {
      const vencimentos: { [key: string]: any } = {};
      dueDate.forEach((item, index) => {
        vencimentos[`vencimento${index + 1}`] = item.value
          ? item.toISOString()
          : null;
      });
      let premiacao: any[] = [];
      if (payPremiation.value?.value) {
        premiacao = premiations.value.map((premiation) => ({
          idTipoPremiacao: premiation.premiationType.id,
          valorPremiacao: Number(premiation.value),
          tipoCalculo: premiation.calcType,
        }));
      }
      let body = {
        idSolicitacao: solicitationData?.idSolicitacao,
        dataSolicitacao: solicitationDate.toISOString(),
        descricao: description.value,
        valorContrato: Number(value.value),
        precoMedio: Number(averagePrice.value),
        valorJuros: Number(interestPerMonth.value),
        dataEntrega: deliveryDoc.toISOString(),
        documentoEntregue: deliveredDoc.value?.value ? "S" : "N",
        cobrarFrete: chargeShipping.value?.value ? "S" : "N",
        valorFrete: Number(shippingValue.value),
        embutirFrete: embedShipping.value?.value ? "S" : "N",
        quantidadeTotal: Number(totalQuantity.value),
        pagarPremiacao: payPremiation.value?.value ? "S" : "N",
        vendedores: sellers.value.map((item) => Number(item.value.id)),
        supervisores: supervisors.value.map((item) => Number(item.value.id)),
        premiacao,
        ...vencimentos,
      };
      try {
        savingReview.setLoading(true);
        const json = await customFetch(
          "/contracts/commercialSendContractReview",
          {
            body,
          }
        );
        if (json.status === 200) {
          changesDetected.reset();
          searchTotalSolicitationRecordsAndSolicitations(
            solicitationListFilters.description.value,
            solicitationListFilters.status.value?.value
          );
          selectedSolicitation.status = Number(SolicitationStatus.Revisado);
          selectedSolicitation.nomeStatus = "Revisado";
          await Modal.success(json.message);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        savingReview.setLoading(false);
      }
    },
    [
      Modal,
      solicitationDate,
      averagePrice,
      chargeShipping,
      shippingValue,
      embedShipping,
      customFetch,
      deliveredDoc,
      deliveryDoc,
      description,
      dueDate,
      interestPerMonth,
      payPremiation,
      savingReview,
      searchTotalSolicitationRecordsAndSolicitations,
      sellers,
      solicitationData,
      supervisors,
      totalQuantity,
      value,
      premiations,
      solicitationListFilters,
    ]
  );

  const handleApproveSolicitation = React.useCallback(async () => {
    let body = {
      idSolicitacao: solicitationData?.idSolicitacao,
      valorContrato: Number(value.value),
    };
    try {
      approvingSolicitation.setLoading(true);
      const json = await customFetch(`/contracts/customerApproveContract`, {
        body,
      });
      if (json.status === 200) {
        searchTotalSolicitationRecordsAndSolicitations(
          solicitationListFilters.description.value,
          solicitationListFilters.status.value?.value
        );
        await Modal.success(json.message);
        navigate("/contratos/gerente/solicitacoes-aguardando-cliente");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      approvingSolicitation.setLoading(false);
    }
  }, [
    Modal,
    approvingSolicitation,
    value,
    customFetch,
    navigate,
    searchTotalSolicitationRecordsAndSolicitations,
    solicitationData,
    solicitationListFilters,
  ]);

  const handleDisapproveSolicitation = React.useCallback(async () => {
    let body = {
      idSolicitacao: solicitationData?.idSolicitacao,
    };
    try {
      disapprovingSolicitation.setLoading(true);
      const json = await customFetch(
        `/contracts/customerDisapproveContract/${solicitationData?.idSolicitacao}`,
        {
          method: "DELETE",
          body,
        }
      );
      if (json.status === 200) {
        searchTotalSolicitationRecordsAndSolicitations(
          solicitationListFilters.description.value,
          solicitationListFilters.status.value?.value
        );

        await Modal.success(json.message);
        navigate("/contratos/gerente/solicitacoes-aguardando-cliente");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      disapprovingSolicitation.setLoading(false);
    }
  }, [
    Modal,
    customFetch,
    disapprovingSolicitation,
    navigate,
    searchTotalSolicitationRecordsAndSolicitations,
    solicitationData,
    solicitationListFilters,
  ]);

  return {
    solicitationData,
    permissions,
    gettingSolicitationData: gettingSolicitationData.isLoading,
    savingReview: savingReview.isLoading,
    approvingSolicitation: approvingSolicitation.isLoading,
    disapprovingSolicitation: disapprovingSolicitation.isLoading,
    getSolicitationData,
    handleSubmit: {
      saveReview: handleSaveReview,
      approve: handleApproveSolicitation,
      disapprove: handleDisapproveSolicitation,
    },
  };
}
