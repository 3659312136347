import React from "react";
import {
  IContractData,
  IContractPremiations,
  IContractSellers,
  IContractSupervisors,
  IContractTable,
} from "../../../types";
import { formatDate } from "../../../../../helpers/format";
import { useModal } from "../../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { IUseSolicitation } from "../../../components/hooks/types";
import dayjs from "dayjs";
import useContractExcel, { ExcelType } from "../../../hooks/useContractExcel";

interface Props {
  contractData: IContractData;
  contractPremiations: IContractPremiations;
  contractSellers: IContractSellers;
  contractSupervisors: IContractSupervisors;
  contractTable: IContractTable;
}

export function useSolicitation({
  contractData,
  contractPremiations,
  contractSellers,
  contractSupervisors,
  contractTable,
}: Props): IUseSolicitation {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const contractExcel = useContractExcel();

  const {
    solicitationDate,
    table,
    description,
    customer,
    value,
    averagePrice,
    interestPerMonth,
    // deliveryDoc,
    chargeShipping,
    shippingValue,
    embedShipping,
    dueDate,
    totalQuantity,
    productGroup,
    payPremiation,
  } = contractData;
  const { premiations } = contractPremiations;
  const { sellers } = contractSellers;
  const { supervisors } = contractSupervisors;
  const { items, defaultColor, defaultLetter } = contractTable;

  const sendingSolicitation = useLoading();

  const callModalGenerateClienteExcel = React.useCallback(
    async (successMessage: string) => {
      const confirm = await Modal.confirm(
        successMessage + "<br /><br />Deseja gerar a planilha excel?"
      );

      const regex = /\d+$/;

      const numeroStringRegex = successMessage.trim().match(regex);

      if (confirm && numeroStringRegex) {
        const numero = parseInt(numeroStringRegex[0]);

        contractExcel.exportExcel(numero, ExcelType.MODELO_CLIENTE);
      }
    },
    [Modal, contractExcel]
  );

  const sendSolicitation = React.useCallback(() => {
    return new Promise<boolean>(async (resolve) => {
      const vencimentos: { [key: string]: any } = {};
      dueDate.forEach((item, index) => {
        vencimentos[`dataVencimento${index + 1}`] = item.value
          ? item.toISOString()
          : null;
      });
      let premiacao: any[] = [];
      if (payPremiation.value?.value) {
        premiacao = premiations.value.map((premiation) => ({
          idTipoPremiacao: premiation.premiationType.id,
          valorPremiacao: Number(premiation.value),
          tipoCalculo: premiation.calcType,
        }));
      }
      const body = {
        idTabelaVigencia: table.value?.value.idTabelaVigencia,
        dataSolicitacao: solicitationDate.toISOString(),
        valorContrato: Number(value.value),
        precoMedio: Number(averagePrice.value),
        // dataEntrega: deliveryDoc.toISOString(),
        cobrarFrete: chargeShipping.value?.value ? "S" : "N",
        valorFrete: Number(shippingValue.value),
        embutirFrete: embedShipping.value?.value ? "S" : "N",
        descricao: description.value,
        idCliente: customer.value?.value.id,
        quantidadeTotal: Number(totalQuantity.value),
        idGrupo: productGroup.value?.value.id,
        vendedores: sellers.value.map((item) => ({
          idVendedor: item.value.id,
        })),
        supervisores: supervisors.value.map((item) => ({
          idSupervisor: item.value.id,
        })),
        transaction: formatDate(new Date(), "yyyy-MM-ddhh:mm:ss"),
        valorJuros: Number(interestPerMonth.value),
        pagarPremiacao: payPremiation.value?.value ? "S" : "N",
        // valorPremiacao: Number(premiationValue.value),
        premiacao,
        corPadraoFaixa: defaultColor.value?.value,
        letraPadraoFaixa: defaultLetter.value,
        itens: items.value
          .filter((item) => item.controll.selected)
          .map((item) => ({
            idItemVigencia: item.idItemVigencia,
            idSolicitacao: 0,
            idFaixa: item.faixaPreco.find((i) => i.escolhida)?.idFaixa,
            idFaixaPrecoProduto: item.faixaPreco.find((i) => i.escolhida)
              ?.idFaixaPrecoProduto,
            precoCusto: item.faixaPreco.find((i) => i.escolhida)?.precoVenda,
          })),
        ...vencimentos,
      };
      try {
        sendingSolicitation.setLoading(true);
        const json = await customFetch("/contracts/sendContractSolicitation", {
          body,
        });
        if (json.status === 200) {
          // Modal.success(json.message);
          callModalGenerateClienteExcel(json.message);
          resolve(true);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
          resolve(false);
        }
      } catch (error) {
        Modal.error(error);
        resolve(false);
      } finally {
        sendingSolicitation.setLoading(false);
      }
    });
  }, [
    Modal,
    premiations,
    solicitationDate,
    averagePrice,
    chargeShipping,
    shippingValue,
    embedShipping,
    customFetch,
    defaultColor,
    defaultLetter,
    description,
    customer,
    dueDate,
    interestPerMonth,
    items,
    payPremiation,
    productGroup,
    sellers,
    sendingSolicitation,
    supervisors,
    table,
    totalQuantity,
    value,
    callModalGenerateClienteExcel,
  ]);

  const validateDate = React.useCallback(
    (deliveryDoc: string, firstDueDate: string) => {
      return new Promise<boolean>(async (resolve) => {
        const today = new Date();
        const delivery = dayjs(deliveryDoc);
        const firstDue = dayjs(firstDueDate);

        if (dayjs(delivery).isBefore(today, "date")) {
          const confirm = await Modal.confirm(
            "A data de entrega da documentação é menor que a data de hoje.</br>Deseja continuar?"
          );
          resolve(confirm);
        } else if (dayjs(firstDue).isBefore(delivery, "date")) {
          Modal.error(
            "A data de vencimento não pode ser menor que a data de entrega da documentação."
          );
          resolve(false);
        } else if (dayjs(firstDue).isBefore(today, "date")) {
          const confirm = await Modal.confirm(
            "A data de vencimento é menor que a data de hoje.</br>Deseja continuar?"
          );
          resolve(confirm);
        }
        resolve(true);
      });
    },
    [Modal]
  );

  return {
    sendSolicitation,
    sendingSolicitation: sendingSolicitation.isLoading,
    exportingExcel: contractExcel.exportingExcel,
    validateDate,
  };
}
