import React from "react";
import { useAuth, useChanges, useModal } from "../../../../hooks/contexts";
import { useLocation } from "react-router-dom";

import { Button } from "../../../../components/Form/Buttons/Button/Button";

import { User } from "phosphor-react";

import styles from "./Topbar.module.css";
import { Notifications } from "./components/Notifications/Notifications";

export function Topbar({ className, openMenuMobile }) {
  const Auth = useAuth();
  const Changes = useChanges();
  const Modal = useModal();

  const location = useLocation();

  function pathname() {
    return `<span>${location.pathname.substring(1).split("/").join(`</span> > <span>`)}</span>`;
  }

  function firstName(name) {
    const first = name.replace(/^(\w+)\s.+/, "$1");
    return first;
  }

  async function handleSignOut() {
    if (Changes.isChangesDetected) {
      const allowNavigate = await Modal.confirm(
        "Você realizou mudanças que ainda não foram salvas!<br>Deseja sair mesmo assim?"
      );
      if (allowNavigate) {
        Changes.reset();
        Auth.signOut();
      }
    } else {
      Auth.signOut();
    }
  }

  return (
    <aside className={`${styles.container} ${className}`}>
      <button className={styles.menuMobile} onClick={openMenuMobile}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <span className={styles.pathIndicator} dangerouslySetInnerHTML={{ __html: pathname() }}></span>
      <div className={styles.rightArea}>
        <Notifications />
        <div className={styles.userButton}>
          <span className={styles.userIcon}>
            <User size={20} color="var(--gray-10)" weight="fill" />
          </span>
          {firstName(Auth.user)}
          <div className={styles.userOptions}>
            <ul>
              <li>
                <Button className={styles.optionButton} onClick={handleSignOut}>
                  Sair
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
}
