import React from "react";

import { Route, Routes } from "react-router-dom";

import { useCustomFetch, useLoading } from "../../../hooks/async";
import { useModal } from "../../../hooks/contexts";
import { usePagination } from "../../../hooks/pagination";

import { RequestsList } from "./RequestsList/RequestsList";

export function PremiationsRequestsProof({ sector }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const pagination = usePagination(100);

  const [requestList, setRequestList] = React.useState([]);

  const searchingRequests = useLoading();

  const searchTotalRequestRecordsPromise = React.useCallback(() => {
    let url = "";
    if (sector === "manager") {
      url = "/premiations/historical/manager/searchAllRequestRecords";
    } else if (sector === "commercial") {
      url = "/premiations/historical/commercial/searchAllRequestRecords";
    } else if (sector === "financial") {
      url = "/premiations/historical/financial/searchAllRequestRecords";
    }

    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch(url, {
          body: {
            tamanho: pagination.maxItems,
            status: ["2", "3", "4", "5", "6"],
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch, pagination, sector]);

  const searchRequestsPromise = React.useCallback(
    (page = 1) => {
      let url = "";
      if (sector === "manager") {
        url = "/premiations/historical/manager/searchAllRequestsPaginated";
      } else if (sector === "commercial") {
        url = "/premiations/historical/commercial/searchAllRequestsPaginated";
      } else if (sector === "financial") {
        url = "/premiations/historical/financial/searchAllRequestsPaginated";
      }

      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch(url, {
            body: {
              pagina: page - 1,
              tamanho: 100,
              status: ["2", "3", "4", "5", "6"],
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, sector]
  );

  const searchRequests = React.useCallback(
    async (page) => {
      try {
        searchingRequests.setLoading(true);
        const json = await searchRequestsPromise(page);
        if (json.status === 200) {
          setRequestList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setRequestList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingRequests.setLoading(false);
      }
    },
    [Modal, searchRequestsPromise, searchingRequests]
  );

  const searchTotalRequestRecordsAndRequests = React.useCallback(async () => {
    try {
      searchingRequests.setLoading(true);
      const jsonRequests = await searchRequestsPromise();
      if (jsonRequests.status === 200) {
        const jsonRecords = await searchTotalRequestRecordsPromise();
        if (jsonRecords.status === 200) {
          setRequestList(jsonRequests.object);
          pagination.setTotalRecords(jsonRecords.object.total);
        } else if (jsonRecords.status === 500) {
          Modal.error(jsonRecords.message, jsonRecords.object);
        } else {
          setRequestList([]);
        }
      } else if (jsonRequests.status === 500) {
        Modal.error(jsonRequests.message, jsonRequests.object);
      } else {
        setRequestList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingRequests.setLoading(false);
    }
  }, [Modal, pagination, searchRequestsPromise, searchTotalRequestRecordsPromise, searchingRequests]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequestsList
            requestList={{
              value: requestList,
              setValue: setRequestList,
              isSearching: searchingRequests.isLoading,
            }}
            searchTotalRequestRecordsAndRequests={searchTotalRequestRecordsAndRequests}
            searchRequests={searchRequests}
            pagination={pagination}
          />
        }
      />
    </Routes>
  );
}
