import React from "react";

import { Route, Routes } from "react-router-dom";

import { useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { usePagination } from "../../../../hooks/pagination";

import { PendingRequestsList } from "./PendingRequestsList/PendingRequestsList";
import { RequestedPremiations } from "./RequestedPremiations/RequestedPremiations";

export function PremiationsCommercialRequests() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const pagination = usePagination(100);

  const filteredManager = useSelect({ type: "single", required: false });

  const [managerOptions, setManagerOptions] = React.useState([]);
  const [requestList, setRequestList] = React.useState([]);

  const [selectedRequest, setSelectedRequest] = React.useState(null);

  const searchingManagers = useLoading();
  const searchingRequests = useLoading();

  const searchManagers = React.useCallback(
    async (manager = "") => {
      try {
        searchingManagers.setLoading(true);
        const json = await customFetch("/premiations/commercial/searchManagers", {
          body: {
            nome: manager,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.idUsuario} | ${option.nome}`,
            };
          });
          setManagerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setManagerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingManagers.setLoading(false);
      }
    },
    [Modal, customFetch, searchingManagers]
  );

  const searchTotalRequestRecordsPromise = React.useCallback(
    (managerId = null) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/premiations/commercial/searchTotalRequestRecords", {
            body: {
              tamanho: pagination.maxItems,
              idUsuario: managerId,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchRequestsPromise = React.useCallback(
    (managerId = null, page = 1) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/premiations/commercial/searchRequestsPaginated", {
            body: {
              pagina: page - 1,
              tamanho: 100,
              idUsuario: managerId,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchRequests = React.useCallback(
    async (managerId = null, page) => {
      try {
        searchingRequests.setLoading(true);
        const json = await searchRequestsPromise(managerId, page);
        if (json.status === 200) {
          setRequestList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setRequestList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingRequests.setLoading(false);
      }
    },
    [Modal, searchRequestsPromise, searchingRequests]
  );

  const searchTotalRequestRecordsAndRequests = React.useCallback(
    async (managerId = null) => {
      if (managerId === null) {
        filteredManager.reset();
      }
      try {
        searchingRequests.setLoading(true);
        const jsonRequests = await searchRequestsPromise(managerId);
        if (jsonRequests.status === 200) {
          const jsonRecords = await searchTotalRequestRecordsPromise(managerId);
          if (jsonRecords.status === 200) {
            setRequestList(jsonRequests.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setRequestList([]);
          }
        } else if (jsonRequests.status === 500) {
          Modal.error(jsonRequests.message, jsonRequests.object);
        } else {
          setRequestList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingRequests.setLoading(false);
      }
    },
    [Modal, filteredManager, pagination, searchRequestsPromise, searchTotalRequestRecordsPromise, searchingRequests]
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PendingRequestsList
            filteredManager={filteredManager}
            managerOptions={{
              value: managerOptions,
              setValue: setManagerOptions,
              isSearching: searchingManagers.isLoading,
            }}
            requestList={{
              value: requestList,
              setValue: setRequestList,
              isSearching: searchingRequests.isLoading,
            }}
            searchManagers={searchManagers}
            searchTotalRequestRecordsAndRequests={searchTotalRequestRecordsAndRequests}
            searchRequests={searchRequests}
            pagination={pagination}
            setSelectedRequest={setSelectedRequest}
          />
        }
      />
      <Route
        path="premiacoes"
        element={
          <RequestedPremiations
            selectedRequest={selectedRequest}
            searchTotalRequestRecordsAndRequests={searchTotalRequestRecordsAndRequests}
          />
        }
      />
    </Routes>
  );
}
