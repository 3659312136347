import React from "react";

import { IContractData, IContractTable, ITableItem } from "../../types";
import { Button, Input, Select } from "../../../../components/Form";
import { formatMoney } from "../../../../helpers/format";
import { Circle } from "../../../../components/Loading";
import { useForm } from "../../../../hooks/form";
import { isValid } from "../../../../helpers/validations";

import styles from "./ContractTable.module.css";

export enum TipoExibicaoTabelaContrato {
  TODAS,
  SELECIONADA_E_DE_ACORDO_COM_COR_E_LETRA_PADRAO,
  NAO_SELECIONADA_OU_COM_COR_OU_LETRA_DIFERENTE_PADRAO,
}

interface Props {
  data: IContractTable;
  table: IUseSelect<any, "single">;
  contractData?: IContractData;
  changeTable: IContractData["changeTable"];
  colorOptions: [
    ISelectOption<string>[],
    React.Dispatch<React.SetStateAction<ISelectOption<string>[]>>
  ];
  interestCalculated: number;
  calculatingInterest: boolean;
  disabled?: boolean;
  disabledRanges?: boolean;
  tipoExibicaoTabelaContrato?: TipoExibicaoTabelaContrato;
}

const isAllItemsSelected = (items: ITableItem[]) => {
  return items.every((item) => item.controll?.selected);
};

export function ContractTable({
  data,
  table,
  contractData,
  changeTable,
  colorOptions: [colorOptions, setColorOptions],
  interestCalculated,
  calculatingInterest,
  disabled = false,
  disabledRanges = false,
  tipoExibicaoTabelaContrato = TipoExibicaoTabelaContrato.TODAS,
}: Props) {
  const { items, defaultColor, defaultLetter, qtdMarcar } = data;

  const modalSearchFormulaRef = React.useRef<HTMLDialogElement>(null);
  const searchedFormula = useForm({ required: true }); //Quantidade de fórmula que será preciso marcar para gerar o preço médio

  const totalItemsToAveragePrice = React.useMemo(() => {
    return items.value.filter((item) => item.controll?.selectedToAveragePrice)
      .length;
  }, [items.value]);

  const scrollToFormula = React.useCallback((search: string) => {
    const formulas = document.querySelectorAll(`[data-formula]`);
    if (formulas.length) {
      const formula = Array.from(formulas).find((formula) =>
        formula.getAttribute("data-formula")?.includes(search.toUpperCase())
      );
      if (formula)
        formula.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  const defineColorOptions = React.useCallback(
    (items: ITableItem[]) => {
      const colors: { [key: string]: string } = {};
      items.forEach((item) => {
        item.faixaPreco.forEach((range) => {
          if (!colors[`${range.cor}`]) colors[`${range.cor}`] = range.cor;
        });
      });
      const colorOptions = [];
      for (const key in colors) {
        colorOptions.push({
          value: key,
          label: (
            <div className={styles.selectColorLabel}>
              <span
                className={styles.selectOptionBall}
                style={{ backgroundColor: key }}
              />
              <p>{key}</p>
            </div>
          ),
        });
      }
      setColorOptions(colorOptions);
    },
    [setColorOptions]
  );

  const onSelectRangesByColorAndLetter = (color: string, letter: string) => {
    items.value.forEach((item) => {
      if (color && letter) {
        let hasCompatibleRange = false;

        item.faixaPreco.forEach((range) => {
          if (range.cor === color && range.letra === letter) {
            range.escolhida = true;
            item.precoVenda = range.precoVenda;
            hasCompatibleRange = true;
          } else range.escolhida = false;
        });

        if (!hasCompatibleRange) {
          item.controll.selected = false;
          item.controll.selectedToAveragePrice = false;
        }
      } else if (color) {
        item.faixaPreco.forEach((range) => {
          if (
            range.cor === color &&
            (range.letra === null || range.letra.trim() === "")
          ) {
            range.escolhida = true;
            item.precoVenda = range.precoVenda;
          } else range.escolhida = false;
        });
      } else {
        item.faixaPreco.forEach((range) => {
          range.escolhida = false;
        });
        item.precoVenda = 0;
      }
    });
    items.setValue(JSON.parse(JSON.stringify(items.value)));
  };

  const onSelectRange = (
    item: ITableItem,
    range: ITableItem["faixaPreco"][0]
  ) => {
    if (range.escolhida) {
      range.escolhida = false;
      item.precoVenda = 0;
      items.setValue(JSON.parse(JSON.stringify(items.value)));
      return;
    }
    item.faixaPreco.forEach((range) => (range.escolhida = false));
    range.escolhida = !range.escolhida;
    item.precoVenda = range.escolhida ? range.precoVenda : 0;
    items.setValue(JSON.parse(JSON.stringify(items.value)));
  };

  const handleChangeCheckItem = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: ITableItem,
    changed: "useFormula" | "useToAveragePrice"
  ) => {
    if (changed === "useFormula") {
      item.controll.selected = event.currentTarget.checked;
      if (item.controll.selectedToAveragePrice)
        item.controll.selectedToAveragePrice = false;
    } else {
      if (event.currentTarget.checked && totalItemsToAveragePrice === qtdMarcar)
        return;
      item.controll.selectedToAveragePrice = event.currentTarget.checked;
    }
    items.setValue([...items.value]);
  };

  const handleChangeSelectAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    items.value.forEach((item) => {
      item.controll.selected = event.currentTarget.checked;
      item.controll.selectedToAveragePrice = false;
    });
    items.setValue([...items.value]);
  };

  const isDefaultColorAndLetterSelected = (faixa: ITableItem["faixaPreco"]) => {
    const selected = faixa.find((item) => item.escolhida);

    return (
      selected?.cor === defaultColor.value?.value &&
      selected?.letra === defaultLetter.value
    );
  };

  React.useEffect(() => {
    defineColorOptions(items.value);
  }, [defineColorOptions, items.value]);

  React.useEffect(() => {
    if (totalItemsToAveragePrice === qtdMarcar) {
      let averagePrice = 0;
      items.value
        .filter((item) => item.controll?.selectedToAveragePrice)
        .forEach((item) => {
          averagePrice += item.precoVenda / interestCalculated;
          // averagePrice += item.precoVenda;
        });
      contractData?.averagePrice.setValue(String(averagePrice / qtdMarcar));
    }
  }, [
    contractData?.averagePrice,
    interestCalculated,
    items.value,
    totalItemsToAveragePrice,
    qtdMarcar,
  ]);

  React.useEffect(() => {
    if (
      contractData?.value !== undefined &&
      contractData?.averagePrice !== undefined
    ) {
      const qtdMedia =
        Number(contractData.value.value) /
        Number(contractData.averagePrice.value);

      contractData.totalQuantity.setValue(String(qtdMedia));
    }
  }, [contractData?.averagePrice, contractData?.value]); // eslint-disable-line

  return (
    <>
      <div>
        <div>
          <label htmlFor="table" className="label">
            Tabela de Vigência
          </label>
          <Select
            id="table"
            placeholder="Selecione uma tabela de vigência"
            options={[]}
            value={table.value}
            error={table.error}
            onBlur={table.onBlur}
            isDisabled
          />
        </div>
        <div className={styles.contentContainer}>
          {items.value.length && !changeTable.searchingTableItems ? (
            <div className={styles.itemsContainer}>
              {!disabledRanges && (
                <>
                  <div>
                    <button
                      className={styles.itemContainer__searchFormulaButton}
                      onClick={() => {
                        modalSearchFormulaRef.current?.showModal();
                      }}
                    >
                      Buscar Fórmula
                    </button>
                  </div>
                  <span className="separator" />
                </>
              )}
              <div className={styles.itemListContainer__selectByColorContainer}>
                <div>
                  <label htmlFor="defaultColor" className="label">
                    Cor Padrão
                  </label>
                  <Select
                    id="defaultColor"
                    placeholder="Selecione a cor"
                    value={defaultColor.value}
                    error={defaultColor.error}
                    onChange={defaultColor.onChange}
                    options={colorOptions}
                    isSearchable={false}
                    isDisabled={disabledRanges}
                  />
                </div>
                <div>
                  <label htmlFor="defaultLetter" className="label">
                    Letra Padrão
                  </label>
                  <Input
                    id="defaultLetter"
                    placeholder="Digite a letra"
                    maxLength={1}
                    value={defaultLetter.value}
                    error={defaultLetter.error}
                    onChange={(e) => {
                      if (
                        e.target.value !== "" &&
                        !/^[A-Za-z]$/.test(e.target.value)
                      )
                        return;
                      defaultLetter.setValue(e.target.value.toUpperCase());
                    }}
                    disabled={disabledRanges}
                    autoComplete="off"
                  />
                </div>
                {!disabledRanges && (
                  <Button
                    onClick={() => {
                      onSelectRangesByColorAndLetter(
                        defaultColor.value?.value,
                        defaultLetter.value
                      );
                    }}
                  >
                    Selecionar
                  </Button>
                )}
              </div>

              <div className={styles.selectAllContainer}>
                <label className="label">
                  <input
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                    onChange={handleChangeSelectAll}
                    checked={isAllItemsSelected(items.value)}
                    disabled={disabled}
                  />
                  <span>Selecionar Todos os Itens</span>
                </label>
              </div>
              <div className={styles.itemsContainer__itemList}>
                {items.value
                  .filter((item) => {
                    switch (tipoExibicaoTabelaContrato) {
                      case TipoExibicaoTabelaContrato.SELECIONADA_E_DE_ACORDO_COM_COR_E_LETRA_PADRAO:
                        return (
                          item.controll.selected &&
                          isDefaultColorAndLetterSelected(item.faixaPreco)
                        );
                      case TipoExibicaoTabelaContrato.NAO_SELECIONADA_OU_COM_COR_OU_LETRA_DIFERENTE_PADRAO:
                        return (
                          !item.controll.selected ||
                          !isDefaultColorAndLetterSelected(item.faixaPreco)
                        );
                      default:
                        return true;
                    }
                  })
                  .map((item, index) => (
                    <div
                      key={index}
                      className={styles.itemsContainer__itemList__item}
                      data-formula={item.nomeFormulado}
                    >
                      <div>
                        <div
                          className={
                            styles.itemsContainer__itemList__item__title
                          }
                        >
                          <h2
                            className={
                              styles.itemsContainer__itemList__item__title__header
                            }
                          >
                            {item.nomeFormulado}
                          </h2>
                          <div
                            className={
                              styles.itemsContainer__itemList__item__title__selectContainer
                            }
                          >
                            <label
                              className={`${
                                styles.itemsContainer__itemList__item__title__labelSelect
                              } ${
                                styles[
                                  "itemsContainer__itemList__item__title__labelSelect--useFormula"
                                ]
                              } ${
                                item.controll?.selected
                                  ? styles[
                                      "itemsContainer__itemList__item__title__labelSelect--selected"
                                    ]
                                  : null
                              } ${disabled ? styles["disabled"] : null}`}
                            >
                              <input
                                type="checkbox"
                                onChange={(event) => {
                                  handleChangeCheckItem(
                                    event,
                                    item,
                                    "useFormula"
                                  );
                                }}
                                checked={item.controll?.selected}
                                disabled={disabled}
                              />
                              <p>
                                {item.controll?.selected
                                  ? "Remover Fórmula"
                                  : "Usar Fórmula"}
                              </p>
                            </label>
                            <label
                              className={`${
                                styles.itemsContainer__itemList__item__title__labelSelect
                              } ${
                                styles[
                                  "itemsContainer__itemList__item__title__labelSelect--useToAveragePrice"
                                ]
                              } ${
                                item.controll?.selectedToAveragePrice
                                  ? styles[
                                      "itemsContainer__itemList__item__title__labelSelect--selected"
                                    ]
                                  : null
                              } ${
                                disabled ||
                                !item.controll?.selected ||
                                (totalItemsToAveragePrice === qtdMarcar &&
                                  !item.controll?.selectedToAveragePrice)
                                  ? styles["disabled"]
                                  : null
                              }`}
                            >
                              <input
                                type="checkbox"
                                onChange={(event) => {
                                  handleChangeCheckItem(
                                    event,
                                    item,
                                    "useToAveragePrice"
                                  );
                                }}
                                checked={item.controll?.selectedToAveragePrice}
                                disabled={disabled}
                              />
                              <p>
                                {item.controll?.selectedToAveragePrice
                                  ? "Remover do Cálculo de Preço Médio"
                                  : "Usar no Cálculo de Preço Médio"}
                                ({totalItemsToAveragePrice}/{qtdMarcar})
                              </p>
                            </label>
                          </div>
                        </div>
                        <span className={styles.separator} />
                        <div
                          className={
                            styles.itemsContainer__itemList__item__info
                          }
                        >
                          <div
                            className={
                              styles.itemsContainer__itemList__item__info__div
                            }
                          >
                            <h3
                              className={
                                styles.itemsContainer__itemList__item__info__div__title
                              }
                            >
                              Grupo
                            </h3>
                            <span
                              className={
                                styles.itemsContainer__itemList__item__info__div____description
                              }
                            >
                              {item.nomeGrupo}
                            </span>
                          </div>
                          <div
                            className={
                              styles.itemsContainer__itemList__item__info__div
                            }
                          >
                            <h3
                              className={
                                styles.itemsContainer__itemList__item__info__div__title
                              }
                            >
                              Preço de Venda (com juros)
                            </h3>
                            <span
                              className={
                                styles.itemsContainer__itemList__item__info__div__description
                              }
                            >
                              {calculatingInterest ? (
                                <Circle size={20} />
                              ) : item.precoVenda ? (
                                formatMoney(
                                  item.precoVenda / interestCalculated
                                )
                              ) : (
                                "---"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <span className={styles.separator} />
                        {item.faixaPreco?.length ? (
                          <div className={styles.commissionsContainer}>
                            {item.faixaPreco.map((range, commissionIndex) => (
                              <div
                                className={`${styles.commissionBlock} ${
                                  !range.escolhida
                                    ? styles["commissionBlock--notSelected"]
                                    : null
                                }`}
                                onClick={() => {
                                  if (disabledRanges) return;
                                  onSelectRange(item, range);
                                }}
                                key={commissionIndex}
                              >
                                <div className={styles.ball}>
                                  <div
                                    style={{ backgroundColor: range.cor }}
                                  ></div>
                                  <span
                                    style={{ backgroundColor: range.cor }}
                                  ></span>
                                  <p>{range.letra}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p
                            className={
                              styles.itemListContainer__li__withoutRange
                            }
                          >
                            Vigência sem comissões cadastradas
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : changeTable.searchingTableItems ? (
            <div className={`loadingContainer ${styles.loadingContainer}`}>
              <Circle size={100} />
              <span className="loadingMessage">Buscando Dados da Tabela</span>
            </div>
          ) : (
            <p className={`lineCardMessage`}>Busque por uma tabela</p>
          )}
        </div>
      </div>
      <dialog
        className={`modal ${styles.modalSearchFormula}`}
        ref={modalSearchFormulaRef}
        onClose={() => {
          searchedFormula.reset();
        }}
      >
        <div className="modalContent">
          <div className={styles.modalSearchFormula_closeContainer}>
            <button
              onClick={() => {
                modalSearchFormulaRef.current?.close();
              }}
            >
              x
            </button>
          </div>
          <div className={styles.modalSearchFormula_input}>
            <label htmlFor="searchedFormula" className="label">
              Fórmula
            </label>
            <Input
              id="searchedFormula"
              placeholder="Digite a fórmula que deseja buscar"
              value={searchedFormula.value}
              error={searchedFormula.error}
              onChange={searchedFormula.onChange}
            />
          </div>
          <div>
            <Button
              type="button"
              className={styles.modalSearchFormula_searchButton}
              onClick={() => {
                if (isValid(searchedFormula)) {
                  scrollToFormula(searchedFormula.value);
                  modalSearchFormulaRef.current?.close();
                }
              }}
            >
              Buscar
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
}
