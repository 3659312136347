import React from "react";

import { formatDate } from "../../../../../helpers/format";

import { useDebounce } from "../../../../../hooks/async";
import { useNavigate } from "react-router-dom";

import { Button, Select } from "../../../../../components/Form";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../../components/Data/Table";
import { Paginate } from "../../../../../components/Paginate/Paginate";

import { Circle } from "../../../../../components/Loading";
import { Eye } from "phosphor-react";
import styles from "./ApprovedRequestsList.module.css";

export function ApprovedRequestsList({
  filteredManager,
  managerOptions,
  requestList,
  searchManagers,
  searchTotalRequestRecordsAndRequests,
  searchRequests,
  pagination,
  setSelectedRequest,
}) {
  const navigate = useNavigate();

  const searchManagersDebounced = useDebounce((description = "") => {
    searchManagers(description);
  });

  React.useEffect(() => {
    setSelectedRequest(null);
    if (!requestList.value.length) searchTotalRequestRecordsAndRequests();
    if (!managerOptions.length) searchManagers();
  }, []); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div>
        <label htmlFor="filteredManager" className="label">
          Gerente
        </label>
        <Select
          id="filteredManager"
          placeholder="Selecione um gerente para filtrar"
          value={filteredManager.value}
          onChange={(value) => {
            filteredManager.setValue(value);
            searchTotalRequestRecordsAndRequests(value?.value.idUsuario);
          }}
          onInputChange={(value) => searchManagersDebounced(value)}
          options={managerOptions.value}
          isLoading={managerOptions.isSearching}
        />
      </div>
      <div className={styles.dataContainer}>
        {requestList.value.length && !requestList.isSearching ? (
          <div className={styles.requestsTableContainer}>
            {requestList.value.map((supervisorItem, index) => (
              <div className={styles.requestTableSupervisorContainer} key={index}>
                <h2 className={styles.supervisorTitle}>{supervisorItem.nomeSupervisor}</h2>
                <div className={styles.clientItems}>
                  {supervisorItem.clientes.map((clientItem, index) => {
                    return (
                      <div className={styles.table} key={index}>
                        <Table title={clientItem.nomeCliente} className={styles.requestsTable}>
                          <THead>
                            <Tr>
                              <Th>ID Solicitação</Th>
                              <Th>Gerente Solicitante</Th>
                              <Th>Data</Th>
                              <Th></Th>
                            </Tr>
                          </THead>
                          <TBody>
                            {clientItem.premiacoes.map((request, index) => {
                              return (
                                <Tr key={index}>
                                  <Td heading="ID Solicitação" width="10rem">
                                    {request.solicitacoes.idSolicitacao}
                                  </Td>
                                  <Td heading="Gerente Solicitante">{request.usuario.nome}</Td>
                                  <Td heading="Data" width="11.25rem">
                                    {formatDate(request.solicitacoes.dataInsercao, "dd/MM/yy às hh:mm:ss")}
                                  </Td>
                                  <Td heading="Ver Solicitação" width="6.25rem" data-option>
                                    <Button
                                      type="button"
                                      variant="edit"
                                      className={styles.seeRequestButton}
                                      onClick={() => {
                                        setSelectedRequest(request);
                                        navigate("premiacoes");
                                      }}
                                      data-option-button
                                    >
                                      <Eye weight="fill" />
                                    </Button>
                                  </Td>
                                </Tr>
                              );
                            })}
                          </TBody>
                        </Table>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        ) : requestList.isSearching ? (
          <div className={`loadingContainer ${styles.loadingRequestListContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma solicitação encontrada</p>
        )}
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => searchRequests(filteredManager.value?.value?.idUsuario, page)}
        />
      </div>
    </div>
  );
}
