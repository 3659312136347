import React from "react";

import { formatDate } from "../../../../helpers/format";
import { downloadResponseFile } from "../../../../helpers/responses";

import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../hooks/async";

import { Button } from "../../../../components/Form";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../components/Data/Table";
import { Paginate } from "../../../../components/Paginate/Paginate";

import { Circle } from "../../../../components/Loading";
import { DownloadSimple } from "phosphor-react";
import styles from "./RequestsList.module.css";

export function RequestsList({ requestList, searchTotalRequestRecordsAndRequests, searchRequests, pagination }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const generatingRequestProof = useLoading();

  const handleClickGenerateRequestProof = React.useCallback(
    async (requestId) => {
      const url = `/premiations/report/generateRequestReceipt/${requestId}`;

      try {
        generatingRequestProof.setLoading(true);
        const response = await customFetch(url, {
          method: "GET",
        });
        if (response.status === 200) {
          downloadResponseFile({
            file: response.object,
            type: "application/pdf",
            fileName: "comprovante-da-solicitacao.pdf",
          });
        } else if (response.status === 500) {
          Modal.error(response.message, response.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        generatingRequestProof.setLoading(false);
      }
    },
    [Modal, customFetch, generatingRequestProof]
  );

  React.useEffect(() => {
    if (!requestList.value.length) searchTotalRequestRecordsAndRequests();
  }, []); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.dataContainer}>
        {requestList.value.length && !requestList.isSearching && !generatingRequestProof.isLoading ? (
          <div className={styles.requestsTableContainer}>
            {requestList.value.map((clientItem, index) => {
              return (
                <div className={styles.table} key={index}>
                  <Table title={clientItem.nomeCliente} className={styles.requestsTable}>
                    <THead>
                      <Tr>
                        <Th>ID Solicitação</Th>
                        <Th>Gerente Solicitante</Th>
                        <Th>Data</Th>
                        <Th></Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {clientItem.premiacoes.map((request, index) => {
                        return (
                          <Tr key={index}>
                            <Td heading="ID Solicitação" width="10rem">
                              {request.solicitacoes.idSolicitacao}
                            </Td>
                            <Td heading="Gerente Solicitante">{request.usuario.nome}</Td>
                            <Td heading="Data" width="11.25rem">
                              {formatDate(request.solicitacoes.dataInsercao, "dd/MM/yy às hh:mm:ss")}
                            </Td>
                            <Td heading="Ver Solicitação" width="6.25rem" data-option>
                              <Button
                                type="button"
                                variant="edit"
                                className={styles.seeRequestButton}
                                onClick={() => {
                                  handleClickGenerateRequestProof(request.solicitacoes.idSolicitacao);
                                }}
                                data-option-button
                              >
                                <DownloadSimple weight="fill" />
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })}
                    </TBody>
                  </Table>
                </div>
              );
            })}
          </div>
        ) : requestList.isSearching ? (
          <div className={`loadingContainer ${styles.loadingRequestListContainer}`}>
            <Circle size={100} />
          </div>
        ) : generatingRequestProof.isLoading ? (
          <div className={`loadingContainer ${styles.loadingRequestListContainer}`}>
            <Circle size={100} />
            <span className="loadingMessage">Gerando Comprovante</span>
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma solicitação encontrada</p>
        )}
        {!generatingRequestProof.isLoading ? (
          <Paginate
            classNameContainer={styles.paginationContainer}
            totalRecords={pagination.totalRecords}
            maxItems={pagination.maxItems}
            currentPage={pagination.currentPage}
            setCurrentPage={pagination.setCurrentPage}
            onPageChange={(page) => searchRequests(page)}
          />
        ) : null}
      </div>
    </div>
  );
}
