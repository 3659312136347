import React from "react";

import { useContractData } from "../../components/hooks/useContractData";
import { StepsController } from "./components/StepsController/StepsController";
import { ContractData } from "../../components/ContractData/ContractData";
import { useSelectOptions } from "../../components/hooks/useSelectOptions";
import { useContractSellers } from "../../components/hooks/useContractSellers";
import { ContractSellers } from "../../components/ContractSellers/ContractSellers";
import { useContractSupervisors } from "../../components/hooks/useContractSupervisors";
import { ContractSupervisors } from "../../components/ContractSupervisors/ContractSupervisors";
import { useContractTable } from "../../components/hooks/useContractTable";
import {
  ContractTable,
  TipoExibicaoTabelaContrato,
} from "../../components/ContractTable/ContractTable";
import { ContractConference } from "../../components/ContractConference/ContractConference";
import { useSolicitation } from "./hooks/useSolicitation";
import { Circle } from "../../../../components/Loading";
import { useChanges, useModal } from "../../../../hooks/contexts";
import { ContractPremiations } from "../../components/ContractPremiations/ContractPremiations";
import { useContractPremiations } from "../../components/hooks/useContractPremiations";

import styles from "./Solicitation.module.css";
import { PageType } from "../../components/types";

export function ContractsManagerSolicitation() {
  const ChangesDetected = useChanges();
  const Modal = useModal();

  const step = React.useState(0);

  const colorOptions = React.useState<ISelectOption<string>[]>([]);

  const contractData = useContractData();
  const contractPremiations = useContractPremiations();
  const contractSellers = useContractSellers();
  const contractSupervisors = useContractSupervisors();
  const contractTable = useContractTable();

  const {
    productGroupOptions,
    sellerOptions,
    supervisorOptions,
    tableOptions,
    customerOptions,
  } = useSelectOptions();

  const {
    sendSolicitation,
    sendingSolicitation,
    exportingExcel,
    // validateDate
  } = useSolicitation({
    contractData,
    contractPremiations,
    contractSellers,
    contractSupervisors,
    contractTable,
  });

  React.useEffect(() => {
    contractPremiations.getPremiationTypes.handle();
  }, []); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      {!sendingSolicitation && !exportingExcel ? (
        <StepsController
          step={step}
          pages={[
            {
              name: "Dados do contrato",
              page: (
                <ContractData
                  data={contractData}
                  premiations={contractPremiations}
                  tableItems={contractTable.items}
                  tableOptions={tableOptions}
                  customerOptions={customerOptions}
                  productGroupOptions={productGroupOptions}
                  changesDetected={ChangesDetected}
                  page={PageType.GERENTE}
                />
              ),
              validate: contractData.validate,
            },
            contractData.payPremiation.value?.value && {
              name: "Tipos de Premiação",
              page: (
                <ContractPremiations
                  data={contractPremiations}
                  page={PageType.GERENTE}
                />
              ),
              validate: contractPremiations.validate,
            },
            {
              name: "Gerentes",
              page: (
                <ContractSupervisors
                  sellers={{ data: contractSellers, options: sellerOptions }}
                  data={contractSupervisors}
                  supervisorOptions={supervisorOptions}
                  page={PageType.GERENTE}
                />
              ),
              validate: contractSupervisors.validate,
            },
            {
              name: "Vendedores",
              page: (
                <ContractSellers
                  data={contractSellers}
                  supervisors={contractSupervisors.supervisors.value.map(
                    (i) => i.value.id
                  )}
                  sellerOptions={sellerOptions}
                  page={PageType.GERENTE}
                />
              ),
              validate: contractSellers.validate,
            },
            {
              name: "Formulado do Contrato",
              page: (
                <ContractTable
                  data={contractTable}
                  table={contractData.table}
                  contractData={contractData}
                  changeTable={contractData.changeTable}
                  colorOptions={colorOptions}
                  interestCalculated={contractData.interestCalculated[0]}
                  calculatingInterest={
                    contractData.changeInterestRelated.calculatingInterest
                  }
                />
              ),
              validate: contractTable.validate,
            },
            {
              name: "Conferência",
              page: (
                <ContractConference
                  pages={[
                    {
                      name: "Dados do contrato",
                      page: (
                        <ContractData
                          data={contractData}
                          premiations={contractPremiations}
                          tableItems={contractTable.items}
                          tableOptions={tableOptions}
                          customerOptions={customerOptions}
                          productGroupOptions={productGroupOptions}
                          page={PageType.CONFERENCIA}
                        />
                      ),
                    },
                    contractData.payPremiation.value?.value && {
                      name: "Tipos de Premiação",
                      page: (
                        <ContractPremiations
                          data={contractPremiations}
                          page={PageType.CONFERENCIA}
                        />
                      ),
                    },
                    {
                      name: "Gerentes",
                      page: (
                        <ContractSupervisors
                          data={contractSupervisors}
                          supervisorOptions={supervisorOptions}
                          page={PageType.CONFERENCIA}
                        />
                      ),
                    },
                    {
                      name: "Vendedores",
                      page: (
                        <ContractSellers
                          data={contractSellers}
                          supervisors={contractSupervisors.supervisors.value.map(
                            (i) => i.value.id
                          )}
                          sellerOptions={sellerOptions}
                          page={PageType.CONFERENCIA}
                        />
                      ),
                    },
                    {
                      name: "Formulado do Contrato",
                      page: (
                        <ContractTable
                          data={contractTable}
                          colorOptions={colorOptions}
                          table={contractData.table}
                          changeTable={contractData.changeTable}
                          interestCalculated={
                            contractData.interestCalculated[0]
                          }
                          calculatingInterest={
                            contractData.changeInterestRelated
                              .calculatingInterest
                          }
                          disabled
                          disabledRanges
                          tipoExibicaoTabelaContrato={
                            TipoExibicaoTabelaContrato.SELECIONADA_E_DE_ACORDO_COM_COR_E_LETRA_PADRAO
                          }
                        />
                      ),
                    },
                    {
                      name: "Formulado do Contrato (Diferentes do Padrão ou Não Selecionados)",
                      page: (
                        <ContractTable
                          data={contractTable}
                          colorOptions={colorOptions}
                          table={contractData.table}
                          changeTable={contractData.changeTable}
                          interestCalculated={
                            contractData.interestCalculated[0]
                          }
                          calculatingInterest={
                            contractData.changeInterestRelated
                              .calculatingInterest
                          }
                          disabled
                          disabledRanges
                          tipoExibicaoTabelaContrato={
                            TipoExibicaoTabelaContrato.NAO_SELECIONADA_OU_COM_COR_OU_LETRA_DIFERENTE_PADRAO
                          }
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
          ]}
          onFinish={async (setStep) => {
            // const dateValid = await validateDate(
            //   contractData.deliveryDoc.value,
            //   contractData.dueDate[0].value
            // );
            // if (!dateValid) {
            //   setStep(0);
            //   return;
            // }
            const confirm = await Modal.confirm(
              "Deseja realmente enviar a solicitação?"
            );
            if (!confirm) return;
            const result = await sendSolicitation();
            if (result) {
              ChangesDetected.reset();
              contractData.reset();
              contractPremiations.reset();
              contractSellers.reset();
              contractSupervisors.reset();
              contractTable.reset();
              setStep(0);
            }
          }}
        />
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          {sendingSolicitation && (
            <span className="loadingMessage">Fazendo Solicitação</span>
          )}
        </div>
      )}
    </div>
  );
}
