import useNonconformity from "./useNonconformity";

export type IUseNonconformity = ReturnType<typeof useNonconformity>;

export enum TipoPagina {
  pendingManager = 0,
  reprovedManager = 1,
  pendingQuality = 2,
  reprovedQuality = 3,
  approvedQuality = 4,
  reopened = 5,
}

export interface IEmpresa {
  idEmpresa: number;
  idCidade: number;
  cidade: any;
  nomeEmpresa: string;
  cnpj: any;
  inscricaoEstadual: string;
  inscricaoMunicipal: string;
  endereco: string;
  numero: string;
  bairro: string;
  cep: string;
  telefone: string;
  complemento: string;
  nomeFantasia: string;
  situacao: string;
  razaoSocial: any;
  dataModificacao: string;
}

export interface ISeller {
  idVendedor: number;
  nome: string;
  situacao: "A" | "I";
}

export interface IFormulado {
  idFormulado: number;
  descricao: string;
  dataFormulado: string;
  usuarioCriacao: string;
  situacao: string;
  formuladoGrupoId: number;
  formuladoGrupo: any;
}

export interface IItem {
  idPedidoItem: number;
  idEmpresa: number;
  idFormulado: number;
  idPedido: number;
  quantidadeItem: number;
  valorItem: number;
  descontoPercentual: number;
  descontoValor: number;
  acressimoPercentual: number;
  acressimoValor: number;
  valorItemTotal: number;
  precoVendaItem: number;
  dataModificacao: string;
  ordemItem: number;
  idFormuladoSiagri: any;
  idEnvase: string;
  idContrato: number;
  valorEnvase: number;
  cancelado: any;
  dataCancelado: any;
  valorComissao: any;
  quantidadeItemEntregue: any;
  dataEntrega: any;
  cultura: any;
  precoCustoItem: any;
  idTabelaVigencia: any;
  precoVendaItemSemJuros: any;
  idTabelaPreco: any;
  faixaMinimaItem: any;
  faixaMaximaItem: any;
  faixaRGB: any;
  precoVendaDigitado: any;
  jurosAplicado: any;
  jurosTabela: any;
  margemGerada: any;
  jurosValor: any;
  jurosFinal: any;
  comissaoTipo: any;
  quantidadeItemCancelada: any;
  formulado: IFormulado;
}

export interface ICliente {
  colaboradorId: number;
  razaoSocial: string;
  nomeFantasia: string;
  bairro: string;
  numero: string;
  telefone1: string;
  telefone2: string;
  tipoPfPj: string;
  ativo: string;
  statusPlayerMercado: string;
  cep: string;
  cidadeId: number;
  cidade: any;
  cnpj: string;
  clienteColaborador: string;
}

export interface IPedido {
  idPedido: number;
  idEmpresa: number;
  idCliente: number;
  idPropriedade: any;
  idVendedor: number;
  idSupervisor: number;
  idTipoOperacao: number;
  dataPedido: string;
  observacaoComercial: any;
  observacaoComercialByte: string;
  totalBruto: number;
  totalDesconto: number;
  totalLiquido: number;
  situacao: string;
  dataCancelamento: any;
  status: string;
  observacaoCancelamento: any;
  usuarioCancelamento: any;
  dataManuntencao: string;
  valorFrete: number;
  tipoFrete: string;
  hora: string;
  sincronizado: string;
  ordemComercial: string;
  idPedidoTablet: number;
  dataVencimento: any;
  dataVencimento1: string;
  dataVencimento2: any;
  dataVencimento3: any;
  dataVencimento4: any;
  dataVencimento5: any;
  sataVencimento6: any;
  dataVencimento7: any;
  dataVencimento8: any;
  dataVencimento9: any;
  dataVencimento10: any;
  dataVencimento11: any;
  dataVencimento12: any;
  pedidoSiagriId: string;
  serieSiagri: any;
  percentualTerceiros: number;
  valorTerceiros: number;
  contratoId: number;
  pesoBruto: number;
  observacaoPreco: any;
  observacaoPrecoBytr: string;
  versaoApp: any;
  observacaoDesconto: any;
  grupoProduto: any;
  descontoPagamentoAntecipado: any;
  percentualDescontoPagamentoEmDia: any;
  valorDescontoPagamentoEmDia: any;
  observacaoTecnica: any;
  idPedidoSubstituicao: any;
  observacaoFiscalByte: any;
  percentualICMS: any;
  valorICMS: any;
  idClienteFiliaado: any;
  dataEntrega: any;
  erp: number;
  cliente: ICliente;
}

export interface INonconformity {
  idNaoConformidade: number;
  idEmpresa: number;
  idPedido: number;
  idIped: number;
  qtdProdutoInconforme: number;
  qtdProdutoAprovada: number;
  dataCriacao: string;
  vendedorEnvio: number;
  vendedor: {
    idVendedor: number;
    nome: string;
    situacao: "A" | "I";
  };
  motivo: string;
  observacao: string;
  status: number;
  dataManutencao: string;
  item: IItem;
  pedido: IPedido;
  naoConformidadeImagem: {
    id: number;
    idNaoConformidade: number;
    idEmpresa: number;
    url: string;
    urlSigned: string;
  }[];
}
