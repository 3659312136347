import { IUseChanges } from "../../hooks/contexts/useChanges";
import { ISolicitationData } from "./Commercial/Solicitations/Analysis/types";
import {
  ICalcType,
  IPremiation,
  IPremiationType,
} from "./components/hooks/types";

export enum SolicitationStatus {
  Pendente = "0",
  ReprovadoCliente = "1",
  AprovadoCliente = "2",
  Revisado = "3",
  ReprovadoComercial = "4",
  AprovadoComercial = "5",
  ReprovadoFinanceiro = "6",
  AprovadoFinanceiro = "7",
}

export interface IProductGroup {
  id: number;
  descricao: string;
}

export interface ISeller {
  id: number;
  descricao: string;
}

export interface ISupervisor {
  id: number;
  descricao: string;
}

export interface ITable {
  idTabelaVigencia: number;
  nomeTabelaVigencia: string;
  idTabela: number;
  jurosMeses: number;
  desconto: number | null;
  valorFrete: number;
  adicionarFrete: "S" | "N";
  tipoJuros: string;
  diasCobrarJuros: number;
  dataManutencao: string;
  dataCriacao: string;
  ativo: "A" | "I";
  tipoComissao: "M" | "D";
  itensTabelaVigencia: null;
  tabela: null;
}

export interface ITableItem {
  idItemVigencia: number;
  idFormulado: number;
  nomeFormulado: string;
  nomeGrupo: string;
  precoVenda: number;
  controll: {
    selected: boolean;
    selectedToAveragePrice: boolean;
  };
  faixaPreco: {
    idFaixaPrecoProduto: number;
    idFaixa: number;
    letra: string;
    cor: string;
    precoVenda: number;
    escolhida: boolean;
  }[];
}

export type Customer = {
  id: number;
  nomeFantasia: string;
  razaoSocial: string;
  cnpj: string;
};

export interface IContractData {
  interestCalculated: [number, React.Dispatch<React.SetStateAction<number>>];
  type: IUseSelect<any, "single">;
  solicitationDate: IUseDate;
  table: IUseSelect<any, "single">;
  description: IUseForm;
  customer: IUseSelect<Customer, "single">;
  value: IUseForm;
  averagePrice: IUseForm;
  interestPerMonth: IUseForm;
  deliveryDoc: IUseDate;
  deliveredDoc: IUseSelect<any, "single">;
  chargeShipping: IUseSelect<any, "single">;
  shippingValue: IUseForm;
  embedShipping: IUseSelect<any, "single">;
  dueDate: IUseDate[];
  totalQuantity: IUseForm;
  productGroup: IUseSelect<any, "single">;
  payPremiation: IUseSelect<any, "single">;
  handleChangeProductGroup: (
    value: ISelectOption<IProductGroup> | null,
    items: IContractTable["items"]
  ) => void;
  changeTable: {
    /**
     *
     * @param value
     * @param items
     *
     * @description A função handleChangeTable é responsável por alterar o valor da tabela selecionada, e caso o valor seja diferente de null, ela irá buscar os itens da tabela selecionada, caso contrário o valor dos itens será setado como um array vazio.
     */
    handle: (
      value: ISelectOption<ITable> | null,
      items: IContractTable["items"]
    ) => void;
    searchingTableItems: boolean;
  };
  changeInterestRelated: {
    /**
     *
     * @param values
     * @description A função handle é responsável por recalcular o valor dos juros aplicados, de acordo com o juros, data de solicitação e data de vencimento.
     */
    handle: (values: {
      interestPerMonth?: string;
      solicitationDate?: string;
      dueDates?: string[];
    }) => void;
    calculatingInterest: boolean;
  };
  validate: () => boolean;
  reset: () => void;
}

export interface IContractPremiations {
  premiations: {
    typeOptions: ISelectOption<IPremiationType>[];
    calcTypeOptions: ISelectOption<ICalcType>[];
    value: IPremiation[];
    insertPremiations: (premiations: IPremiation[]) => void;
    insertPremiation: (
      premiationType: IUseSelect<ISelectOption<IPremiationType>>,
      calcType: IUseSelect<ISelectOption<ICalcType>>,
      value: IUseForm,
      changesDetected?: IUseChanges
    ) => void;
    removePremiation: (
      premiationType: string,
      changesDetected?: IUseChanges
    ) => void;
  };
  getPremiationTypes: {
    handlePromise: () => Promise<DefaultFetchResponse<IPremiationType[]>>;
    handle: () => void;
    loading: boolean;
  };
  validate: () => boolean;
  reset: () => void;
}

export interface IContractSellers {
  sellers: IUseSelect<any, "multiple">;
  validate: () => boolean;
  reset: () => void;
}

export interface IContractSupervisors {
  supervisors: IUseSelect<any, "multiple">;
  validate: () => boolean;
  reset: () => void;
}

export interface IContractTable {
  items: {
    value: ITableItem[];
    setValue: React.Dispatch<React.SetStateAction<ITableItem[]>>;
  };
  defaultColor: IUseSelect<any, "single">;
  defaultLetter: IUseForm;
  qtdMarcar: number;
  validate: () => boolean;
  reset: () => void;
}

export interface IContractTableAnalysis {
  items: {
    value: ISolicitationData["tabelaVigencia"]["itensTabelaVigencia"];
    setValue: React.Dispatch<
      React.SetStateAction<
        ISolicitationData["tabelaVigencia"]["itensTabelaVigencia"]
      >
    >;
  };
  defaultColor: IUseSelect<any, "single">;
  defaultLetter: IUseForm;
  validate: () => boolean;
  reset: () => void;
}
