import React from "react";
import { Route, Routes } from "react-router-dom";

import Approved from "./Quality/Approved/Approved";
import Pending from "./Quality/Pending/Pending";
import Refused from "./Quality/Refused/Refused";
import PendingManager from "./Manager/Pending/PendingManager";
import RefusedManager from "./Manager/Refused/RefusedManager";

export function Nonconformity() {
  return (
    <Routes>
      <Route path="qualidade/pendentes/*" element={<Pending />} />
      <Route path="qualidade/aprovadas/*" element={<Approved />} />
      <Route path="qualidade/recusadas/*" element={<Refused />} />
      <Route path="gerente/pendentes/*" element={<PendingManager />} />
      <Route path="gerente/recusadas/*" element={<RefusedManager />} />
    </Routes>
  );
}
