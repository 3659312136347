import React from "react";

import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useChanges, useModal } from "../../../../../hooks/contexts";
import { useSelect } from "../../../../../hooks/form";

import { Button, Select } from "../../../../../components/Form";

import { Circle } from "../../../../../components/Loading/Circle/Circle";
import styles from "./UserPermissions.module.css";

export function UserPermissions({ user }) {
  const [isSearchingUserPermissions, setIsSearchingUserPermissions] = React.useState(false);
  const [isSavingPermissions, setIsSavingPermissions] = React.useState(false);

  const [originalPermissions, setOriginalPermissions] = React.useState({
    permissionsGranted: [],
    permissionsNotGranted: [],
  });
  const [permissionsNotGranted, setPermissionsNotGranted] = React.useState([]);
  const [permissionsGranted, setPermissionsGranted] = React.useState([]);
  const [permissionGroups, setPermissionGroups] = React.useState([]);

  const filteredGroup = useSelect({ type: "single", required: false });
  const filteredSystem = useSelect({ type: "single", required: false });

  const [groupOptions, setGroupOptions] = React.useState([]);
  const [systemOptions, setSystemOptions] = React.useState([]);

  const searchingGroupOptions = useLoading();
  const searchingSystemOptions = useLoading();

  const customFetch = useCustomFetch();

  const { isChangesDetected, setIsChangesDetected } = useChanges();
  const Modal = useModal();

  const saveOriginalPermissions = React.useCallback((permissionsGranted, permissionsNotGranted) => {
    setOriginalPermissions({
      permissionsGranted: permissionsGranted,
      permissionsNotGranted: permissionsNotGranted,
    });
  }, []);

  const getUpdatedGroupsData = React.useCallback((groups, permissionsGranted, permissionsNotGranted) => {
    const groupList = [...groups];
    groupList.forEach((group) => {
      group.grantedLength = permissionsGranted.filter((item) => item.tipo === group.title).length;
      group.notGrantedLength = permissionsNotGranted.filter((item) => item.tipo === group.title).length;
    });

    return groupList.sort((a, b) => a.title.localeCompare(b.title));
  }, []);

  const searchPermissionGroups = React.useCallback(
    async (idSystem) => {
      try {
        searchingGroupOptions.setLoading(true);
        const json = await customFetch(`/admin/searchPermissionsType/${idSystem}`, {
          method: "GET",
        });
        if (json.status === 200) {
          const options = json.object.map((item) => ({ value: item, label: item.descricao }));
          setGroupOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingGroupOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingGroupOptions]
  );

  const searchSystems = React.useCallback(async () => {
    try {
      searchingSystemOptions.setLoading(true);
      const json = await customFetch("/admin/searchSystems", {
        method: "GET",
      });
      if (json.status === 200) {
        const options = json.object.map((item) => ({ value: item, label: item.nomeSistema }));
        setSystemOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingSystemOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingSystemOptions]);

  const searchUserPermissions = React.useCallback(
    (idPessoa, idSistema = 0, idTipoPermissao = 0) => {
      setPermissionsNotGranted([]);
      setPermissionsGranted([]);
      (async () => {
        try {
          setIsSearchingUserPermissions(true);
          const json = await customFetch("/admin/searchUserPermissions", {
            body: {
              idPessoa: idPessoa,
              idSistema,
              idTipoPermissao,
              descricaoPermissao: "%%%%",
            },
          });
          if (json.status === 200) {
            const permissions = json.object;
            const permissionsNotGranted = json.object.filter((permission) => permission.status === "I");
            const permissionsGranted = json.object.filter((permission) => permission.status === "A");

            const groups = [];
            permissions.forEach((item) => {
              if (groups.find((group) => group.title === item.tipo) === undefined) {
                groups.push({
                  title: item.tipo,
                  grantedLength: 0,
                  notGrantedLength: 0,
                });
              }
            });

            const updatedGroups = getUpdatedGroupsData(groups, permissionsGranted, permissionsNotGranted);

            saveOriginalPermissions(permissionsGranted, permissionsNotGranted);
            setPermissionsNotGranted(permissionsNotGranted);
            setPermissionsGranted(permissionsGranted);
            setPermissionGroups(updatedGroups);
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          setIsSearchingUserPermissions(false);
        }
      })();
    },
    [Modal, customFetch, getUpdatedGroupsData, saveOriginalPermissions]
  );

  const addPermission = React.useCallback(
    (permission) => {
      const notGrantedList = permissionsNotGranted.filter((item) => item !== permission);
      setPermissionsNotGranted(notGrantedList);

      const grantedList = [...permissionsGranted, permission].sort((a, b) => a.idPermissao - b.idPermissao);
      setPermissionsGranted(grantedList);

      const updatedGroups = getUpdatedGroupsData(permissionGroups, grantedList, notGrantedList);
      setPermissionGroups(updatedGroups);

      setIsChangesDetected(true);
    },
    [getUpdatedGroupsData, permissionGroups, permissionsGranted, permissionsNotGranted, setIsChangesDetected]
  );

  const removePermission = React.useCallback(
    (permission) => {
      const grantedList = permissionsGranted.filter((item) => item !== permission);
      setPermissionsGranted(grantedList);

      const notGrantedList = [...permissionsNotGranted, permission].sort((a, b) => a.idPermissao - b.idPermissao);
      setPermissionsNotGranted(notGrantedList);

      const updatedGroups = getUpdatedGroupsData(permissionGroups, grantedList, notGrantedList);
      setPermissionGroups(updatedGroups);

      setIsChangesDetected(true);
    },
    [getUpdatedGroupsData, permissionGroups, permissionsGranted, permissionsNotGranted, setIsChangesDetected]
  );

  const getInsertedAndRemovedPermissions = React.useCallback(() => {
    const insertedPermissions = permissionsGranted
      .filter((granted) => {
        return originalPermissions.permissionsNotGranted.some(
          (notGranted) => notGranted.idPermissao === granted.idPermissao
        );
      })
      .map((permission) => permission.idPermissao);

    const removedPermissions = permissionsNotGranted
      .filter((notGranted) => {
        return originalPermissions.permissionsGranted.some((granted) => granted.idPermissao === notGranted.idPermissao);
      })
      .map((permission) => permission.idPermissao);

    return [insertedPermissions, removedPermissions];
  }, [originalPermissions, permissionsGranted, permissionsNotGranted]);

  const resetChanges = React.useCallback(() => {
    setIsChangesDetected(false);
    setPermissionsNotGranted([]);
    setPermissionsGranted([]);
    saveOriginalPermissions([], []);
  }, [saveOriginalPermissions, setIsChangesDetected]);

  const confirmFilterChanges = React.useCallback(() => {
    return new Promise(async (resolve) => {
      if (!isChangesDetected) resolve(true);
      else {
        const confirm = await Modal.confirm(
          "Essa alteração irá limpar as mudanças feitas até agora.<br />Deseja continuar?"
        );

        if (confirm) {
          setIsChangesDetected(false);
        }

        resolve(confirm);
      }
    });
  }, [Modal, isChangesDetected, setIsChangesDetected]);

  const savePermissions = React.useCallback(
    (e) => {
      e.preventDefault();

      const [permissoesInseridas, permissoesDesativadas] = getInsertedAndRemovedPermissions();

      (async () => {
        try {
          setIsSavingPermissions(true);
          const json = await customFetch("/admin/saveUserPermissions", {
            body: {
              idPessoa: user.value.idUsuario,
              permissoesInseridas,
              permissoesDesativadas,
            },
          });
          if (json.status === 200) {
            Modal.success("As permissões do usuário foram alteradas<br> com sucesso!");
            saveOriginalPermissions(permissionsGranted, permissionsNotGranted);
            setIsChangesDetected(false);
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          setIsSavingPermissions(false);
        }
      })();
    },
    [
      permissionsGranted,
      permissionsNotGranted,
      customFetch,
      user,
      Modal,
      saveOriginalPermissions,
      setIsChangesDetected,
      getInsertedAndRemovedPermissions,
    ]
  );

  React.useEffect(() => {
    searchSystems();
  }, []); //eslint-disable-line

  return (
    <div>
      <form className={styles.form} onSubmit={savePermissions}>
        <div className={styles.dataContainer}>
          {/* {(permissionsNotGranted.length || permissionsGranted.length) && !isSavingPermissions ? ( */}
          {!isSavingPermissions ? (
            <>
              <span className={styles.separator}></span>
              <div className={styles.filtersContainer}>
                <div className={styles.selectContainer}>
                  <label htmlFor="system" className="label">
                    Filtrar Sistema
                  </label>
                  <Select
                    placeholder="Selecione um sistema para filtrar"
                    options={systemOptions}
                    value={filteredSystem.value}
                    onChange={async (value) => {
                      const confirm = await confirmFilterChanges();
                      if (!confirm) return;
                      else resetChanges();
                      filteredSystem.onChange(value);
                      filteredGroup.reset();
                      if (value) {
                        searchPermissionGroups(value.value.idSistema);
                      } else {
                        setGroupOptions([]);
                      }
                    }}
                    isLoading={searchingSystemOptions.isLoading}
                    isSearchable={false}
                  />
                </div>
                <div className={styles.selectContainer}>
                  <label htmlFor="group" className="label">
                    Filtrar Grupo
                  </label>
                  <Select
                    placeholder="Selecione um grupo para filtrar"
                    options={searchingGroupOptions.isLoading ? [] : groupOptions}
                    value={filteredGroup.value}
                    onChange={async (value) => {
                      const confirm = await confirmFilterChanges();
                      if (!confirm) return;
                      else resetChanges();
                      filteredGroup.onChange(value);
                    }}
                    isLoading={searchingGroupOptions.isLoading}
                    isSearchable={false}
                    isDisabled={!filteredSystem.value}
                  />
                </div>
                <div className={styles.searchPermissionsButton}>
                  <Button
                    type="button"
                    onClick={async () => {
                      if (isChangesDetected) {
                        const confirm = await Modal.confirm("Você tem alterações não salvas.<br />Deseja continuar?");
                        if (confirm) {
                          resetChanges();
                          searchUserPermissions(
                            user.value.idUsuario,
                            filteredSystem.value?.value.idSistema,
                            filteredGroup.value?.value.idPermissaoTipo
                          );
                        }
                      } else {
                        searchUserPermissions(
                          user.value.idUsuario,
                          filteredSystem.value?.value.idSistema,
                          filteredGroup.value?.value.idPermissaoTipo
                        );
                      }
                    }}
                    disabled={!filteredSystem.value || !filteredGroup.value}>
                    Buscar Permissões
                  </Button>
                </div>
              </div>
              <span className={styles.separator}></span>
              {isSearchingUserPermissions ? (
                <>
                  <div className={styles.searchingUserPermissionsLoading}>
                    <Circle size={100} />
                  </div>
                </>
              ) : permissionsNotGranted.length || permissionsGranted.length ? (
                <>
                  <div className={styles.permissionsContainer}>
                    <div>
                      <span className={styles.permissionsTitle}>Permissões Concedidas</span>
                      <ul className={`${styles.permissionGroups}`}>
                        {(
                          filteredGroup.value?.value && filteredGroup.value.value.descricao !== "TODOS"
                            ? permissionsGranted.length &&
                              permissionGroups.find((group) => group.title === filteredGroup.value.value.descricao)
                                ?.grantedLength > 0
                            : permissionsGranted.length > 0
                        ) ? (
                          permissionGroups
                            .filter((group) => {
                              if (filteredGroup.value?.value && filteredGroup.value?.value.descricao !== "TODOS") {
                                return filteredGroup.value?.value
                                  ? group.grantedLength > 0 && filteredGroup.value.value.descricao === group.title
                                  : group.grantedLength > 0;
                              } else {
                                return group.grantedLength > 0;
                              }
                            })
                            .map((group, index) => (
                              <li key={index}>
                                <p className={`${styles.permissionGroupsTitle}`}>{group.title}</p>
                                <ul className={`${styles.permissions}`}>
                                  {permissionsGranted
                                    .filter((permission) => {
                                      return permission.tipo === group.title;
                                    })
                                    .map((permission) => {
                                      return (
                                        <li key={permission.idPermissao}>
                                          <span className={styles.permissionInfoId}>{permission.idPermissao}</span>
                                          <span className={styles.permissionInfoSeparator}></span>
                                          <span className={styles.permissionInfoName}>
                                            <span className={styles.permissionName}>{permission.permissao}</span>
                                            {permission.observacao ? (
                                              <span className={styles.permissionObs}>{permission.observacao}</span>
                                            ) : null}
                                          </span>
                                          <span className={styles.permissionInfoSeparator}></span>
                                          <span>
                                            <button
                                              type="button"
                                              className={`${styles.permissionInfoButton} ${styles.permissionInfoButtonRemove}`}
                                              onClick={() => removePermission(permission)}
                                              disabled={isSavingPermissions}>
                                              Revogar
                                            </button>
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </li>
                            ))
                        ) : (
                          <span className={styles.nonePermissionsMessage}>
                            Nenhuma permissão foi dada ao usuário.{" "}
                            {isChangesDetected ? (
                              <>
                                <br />
                                <strong className={styles.needSaveMessage}>Salve para concluir!</strong>
                              </>
                            ) : null}
                          </span>
                        )}
                      </ul>
                    </div>
                    <div>
                      <span className={styles.permissionsTitle}>Permissões Não Concedidas</span>
                      <ul className={styles.permissionGroups}>
                        {(
                          filteredGroup.value?.value && filteredGroup.value.value.descricao !== "TODOS"
                            ? permissionsNotGranted.length &&
                              permissionGroups.find((group) => group.title === filteredGroup.value.value.descricao)
                                ?.notGrantedLength > 0
                            : permissionsNotGranted.length > 0
                        ) ? (
                          permissionGroups
                            .filter((group) => {
                              if (filteredGroup.value?.value && filteredGroup.value?.value.descricao !== "TODOS") {
                                return filteredGroup.value?.value
                                  ? group.notGrantedLength > 0 && filteredGroup.value.value.descricao === group.title
                                  : group.notGrantedLength > 0;
                              } else {
                                return group.notGrantedLength > 0;
                              }
                            })
                            .map((group, index) => (
                              <li key={index}>
                                <p className={`${styles.permissionGroupsTitle}`}>{group.title}</p>
                                <ul className={`${styles.permissions}`}>
                                  {permissionsNotGranted
                                    .filter((permission) => {
                                      return permission.tipo === group.title;
                                    })
                                    .map((permission) => {
                                      return (
                                        <li key={permission.idPermissao}>
                                          <span className={styles.permissionInfoId}>{permission.idPermissao}</span>
                                          <span className={styles.permissionInfoSeparator}></span>
                                          <span className={styles.permissionInfoName}>
                                            <span className={styles.permissionName}>{permission.permissao}</span>
                                            {permission.observacao ? (
                                              <span className={styles.permissionObs}>{permission.observacao}</span>
                                            ) : null}
                                          </span>
                                          <span className={styles.permissionInfoSeparator}></span>
                                          <span>
                                            <button
                                              type="button"
                                              className={`${styles.permissionInfoButton} ${styles.permissionInfoButtonAdd}`}
                                              onClick={() => addPermission(permission)}
                                              disabled={isSavingPermissions}>
                                              Conceder
                                            </button>
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </li>
                            ))
                        ) : (
                          <span className={styles.nonePermissionsMessage}>
                            Todas as permissões foram dadas ao usuário.{" "}
                            {isChangesDetected ? (
                              <>
                                <br />
                                <strong className={styles.needSaveMessage}>Salve para concluir!</strong>
                              </>
                            ) : null}
                          </span>
                        )}
                      </ul>
                    </div>
                  </div>
                  <Button className={styles.submitButton} disabled={!isChangesDetected || isSavingPermissions}>
                    {isSavingPermissions ? "Salvando..." : "Salvar"}
                  </Button>
                </>
              ) : (
                <p className="lineCardMessage">Busque as permissões que deseja visualizar</p>
              )}
            </>
          ) : isSavingPermissions ? (
            <div className={`loadingContainer ${styles.savingPermissionsLoadingContainer}`}>
              <Circle size={100} />
              <span className="loadingMessage">Salvando Permissões</span>
            </div>
          ) : (
            <div className={styles.unselectedUserMessage}>Selecione um usuário</div>
          )}
        </div>
      </form>
    </div>
  );
}
